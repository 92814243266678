<template>
  <div class="card-body">
    <!-- Formulario de subida -->
    <div
      class="row"
      v-if="
        $store.getters.can('tep.inspecciones.edit') &&
          $parent.estado_documento == 1
      "
    >
      <div class="form-group col-md-5">
        <label for="link_imagen">Subir imagen</label>
        <div class="form-group">
          <input
            type="file"
            id="link_imagen"
            ref="link_imagen"
            class="form-control-file"
            accept=".jpg, .JPG, .png, .PNG"
            @change="elegirDirectorio()"
            multiple
          />
        </div>
      </div>
      <div class="form-group col-md-2">
        <button
          id="guardar_documento"
          type="button"
          class="btn btn-lg btn-success"
          data-html="true"
          title="Guardar Documento"
          data-toggle="tooltip"
          @click="saveImagenes()"
        >
          <i class="fa fa-file-upload"></i> <br />
          Subir
        </button>
      </div>
    </div>
    <!-- Lista de imágenes de la inspección -->
    <div class="row" v-if="inspeccion_id && listaImagenes.length > 0">
      <div class="card col-sm-12">
        <div class="card-body">
          <div class="row">
            <div
              class="card col-sm-3"
              v-for="imagen in listaImagenes"
              :key="imagen.id"
            >
              <div class="card-body p-2 text-center">
                <img
                  :src="uri_docs + imagen.link"
                  class="img-fluid mb-2"
                  style="max-height: 200px"
                  data-toggle="modal"
                  data-target="#modal-image"
                  @click="getModalData(imagen.id, imagen.link)"
                />
              </div>
              <div
                class="card-header"
                v-if="
                  $store.getters.can('tep.inspecciones.edit') &&
                    $parent.estado_documento == 1
                "
              >
                <div class="btn-group float-right">
                  <button
                    type="button"
                    class="btn btn-sm bg-danger"
                    @click="destroyImagen(imagen.id)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- modal -->
        <div class="modal fade" id="modal-image">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-body text-center">
                <img :src="modal_link" class="img-fluid mb-2" />
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-default"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  class="btn bg-danger"
                  data-dismiss="modal"
                  v-if="
                    $store.getters.can('tep.inspecciones.edit') &&
                      $parent.estado_documento == 1
                  "
                  @click="destroyImagen(modal_id)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
      </div>
    </div>
    <div class="row" v-else>
      <div class="alert alert-warning col-12">
        <h5><i class="icon fas fa-exclamation-triangle"></i> Atención!</h5>
        No se encontraron imágenes para esta inspección.
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "inspeccionImages",

  data() {
    return {
      inspeccion_id: null,
      listaImagenes: [],
      link_imagen: null,
      array_Imagenes: [],
      modal_id: null,
      modal_link: null,
      files: null,
      bandera: 0,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    getIndex() {
      this.inspeccion_id = this.$parent.inspeccion_id;
      this.getImages();
    },

    getImages() {
      axios
        .get("/api/tep/inspecciones/imagen", {
          params: {
            inspeccion_id: this.inspeccion_id,
          },
        })
        .then((response) => {
          this.listaImagenes = response.data.data[0].imagenes;
        });
    },

    getModalData(id, link) {
      this.modal_id = id;
      this.modal_link = this.uri_docs + link;
    },

    elegirDirectorio() {
      this.files = this.$refs.link_imagen.files;
    },

    saveImagenes() {
      var vm = this;
      var count = 0;
      let formData = new FormData();
      if (vm.files) {
        formData.append("inspeccion_id", vm.inspeccion_id);
        for (var i = 0; i < vm.files.length; i++) {
          let file = vm.files[i];
          formData.append("link_imagen" + i, file);
          count = i + 1;
        }
        formData.append("total", count);
        axios
          .post("api/tep/inspecciones/imagen", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Las imagenes se guardaron correctamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            vm.files = this.$refs.link_imagen.files;
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar al menos una imagen",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    destroyImagen(id) {
      this.$swal({
        title: "Está seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/tep/inspecciones/imagen/" + id)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "La imagen se eliminó correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  mounted() {
    this.getIndex();
  },
};
</script>
